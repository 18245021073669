import React from "react";

import {
  EQX_PLUS_APP_STORE_URL,
  EQX_PLUS_GOOGLE_PLAY_URL,
  IMAGE_ASSETS_CDN_PATH,
} from "@/constants";

import {
  appleStoreImg,
  googlePlayStoreImg,
  linkContainer,
  mobileStoreLinks,
  mobileStoreLinksContainer,
} from "./index.module.scss";

const MobileStoreLinks = () => {
  return (
    <section className={mobileStoreLinks}>
      <div
        className={`${mobileStoreLinksContainer} col-sm-16 col-md-12 col-lg-16 p-0`}
      >
        <div className={`col p-0 ${linkContainer}`}>
          <a
            href={EQX_PLUS_APP_STORE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              alt={`App Store Link`}
              className={appleStoreImg}
              src={`${IMAGE_ASSETS_CDN_PATH}/apple_store_banner.png`}
            />
          </a>
        </div>
        <div className={`col p-0 ${linkContainer}`}>
          <a
            href={EQX_PLUS_GOOGLE_PLAY_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              alt={`Google Play Store`}
              className={googlePlayStoreImg}
              src={`${IMAGE_ASSETS_CDN_PATH}/google_play_store_banner.png`}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default MobileStoreLinks;
